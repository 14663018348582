import { computed } from 'vue'
import { defineStore } from 'pinia'
import { query, where, collection } from 'firebase/firestore'
import { database } from '@/firebase'
import { useCollection } from 'vuefire'
import { useUserStore } from '@/users/stores'

export const useEventsStore = defineStore('events', () => {
  const { user } = useUserStore()
  const events = useCollection(query(
    collection(database, 'events'),
    where('companyid', '==', user.companyid),
  ))
  const eventsLoading = computed(() => events.pending.value)
  return { events, eventsLoading }
})

export default useEventsStore
