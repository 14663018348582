import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getFunctions /* , connectFunctionsEmulator */ } from 'firebase/functions'
import { getFirestore, getDoc, doc /* , connectFirestoreEmulator */ } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyC5TzPgqhx8m_Y_MzRCOb3jwuhU0Eo0NVI',
  authDomain: 'entrepreneurssite.firebaseapp.com',
  databaseURL: 'https://entrepreneurssite-default-rtdb.firebaseio.com/',
  projectId: 'entrepreneurssite',
  storageBucket: 'entrepreneurssite.appspot.com',
  messagingSenderId: '317545103947',
  appId: '1:317545103947:web:085b106cdbc8ef0ebdc3ce',
  measurementId: 'G-SLGZ4TPL93',
}
// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const database = getFirestore(firebaseApp)
export const functions = getFunctions(firebaseApp)

// export const backupdb = getFirestore(firebaseApp, 'backup1')
export const db = getDatabase(firebaseApp)
if (window.location.hostname === 'localhost') {
  // connectFirestoreEmulator(database, 'localhost', 7777)
  connectDatabaseEmulator(db, 'localhost', 9000)
  // connectFunctionsEmulator(functions, 'localhost', 5001)
}

export const storage = getStorage(firebaseApp)
export const auth = getAuth(firebaseApp)

/* eslint-disable no-param-reassign, no-restricted-syntax, no-await-in-loop */
export const getCurrentUser = async () => new Promise((resolve, reject) => {
  onAuthStateChanged(auth, (user) => {
    resolve(user)
  }, reject)
})

export const getUserProfile = async () => {
  const reference = await getCurrentUser()
  if (!reference) return null
  const docRef = await getDoc(doc(database, 'users', reference.uid))
  return { ...docRef.data(), id: docRef.id }
}

export const isObject = (obj) => !!obj && typeof obj === 'object'
export const isDocumentRef = (obj) => isObject(obj) && obj.type === 'document'

export const recursiveGetDoc = async (obj = {}) => {
  obj = isDocumentRef(obj)
    ? (await getDoc(obj).then((ref) => {
      if (ref.exists()) {
        return { id: ref.id, ...ref.data() }
      }
      return ref
    })) : obj

  for (const [key, ref] of Object.entries(obj)) {
    if (isDocumentRef(ref)) {
      obj[key] = await recursiveGetDoc(ref)
    }
  }

  return obj
}
