import { computed } from 'vue'
import { collection, doc, query, where } from 'firebase/firestore'
import { database } from '@/firebase'
import { defineStore } from 'pinia'
import { useCollection } from 'vuefire'
import { useUserStore } from '@/users/stores'

export const useApplicationsStore = defineStore('applications', () => {
  const { user } = useUserStore()
  const companyref = doc(database, 'companies', user.companyid)
  const companyApplications = useCollection(query(
    collection(database, 'applications'),
    where('company', '==', companyref),
  ), { ssrKey: 'company-applications' })
  const companyApplicationsLoading = computed(() => companyApplications.pending.value)

  return { companyApplications, companyApplicationsLoading }
})

export default useApplicationsStore
