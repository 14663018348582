import { computed } from 'vue'
import { collection, query, where } from 'firebase/firestore'
import { database } from '@/firebase'
import { defineStore } from 'pinia'
import { useCollection } from 'vuefire'
import { useUserStore } from '@/users/stores'

export const useCallsStore = defineStore('calls', () => {
  const { user } = useUserStore()
  const calls = useCollection(query(
    collection(database, 'calls'),
    where('companyid', '==', user.companyid),
  ))
  const callsLoading = computed(() => calls.pending.value)
  return { calls, callsLoading }
})

export default useCallsStore
