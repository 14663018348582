import { computed } from 'vue'
import { defineStore } from 'pinia'
import { query, where, collection } from 'firebase/firestore'
import { database } from '@/firebase'
import { useCollection } from 'vuefire'
import { useUserStore } from '@/users/stores'

export const useEmailsStore = defineStore('emails', () => {
  const { user } = useUserStore()
  const emailthreads = useCollection(query(
    collection(database, 'emailthreads'),
    where('companyid', '==', user.companyid),
  ))
  const emailthreadsLoading = computed(() => emailthreads.pending.value)
  return { emailthreads, emailthreadsLoading }
})

export default useEmailsStore
