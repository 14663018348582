import { ref, computed } from 'vue'
import { doc, query, collection, where } from 'firebase/firestore'
import { auth, database, getUserProfile } from '@/firebase'
import { signInWithCredential } from 'firebase/auth'
import { useDocument, useCollection } from 'vuefire'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', () => {
  const user = ref(null)
  // const auth = useFirebaseAuth()

  async function userProfile() {
    user.value = await getUserProfile()
  }
  async function signInUser(credential) {
    await signInWithCredential(auth, credential)
    await userProfile()
  }
  async function signOutUser() {
    await auth.signOut()
    user.value = null
  }
  return { user, userProfile, signInUser, signOutUser }
})

export const useUserContactStore = defineStore('usercontact', () => {
  const userStore = useUserStore()
  const user = computed(() => userStore.user)
  const contact = useDocument(doc(database, 'contacts', user.value.contactid ? user.value.contactid : '_nobody_'))
  const contactLoading = computed(() => contact.pending.value)

  return { contact, contactLoading }
})

export const useUserCompanyStore = defineStore('usercompany', () => {
  const userStore = useUserStore()
  const user = computed(() => userStore.user)
  const company = useDocument(doc(database, 'companies', user.value.companyid ? user.value.companyid : '_nocompany_'))
  const companyLoading = computed(() => company.pending.value)
  const companyUsers = useCollection(query(
    collection(database, 'users'),
    where('companyid', '==', user.value.companyid),
  ))
  const companyUsersLoading = computed(() => companyUsers.pending.value)

  return {
    company, companyLoading, companyUsers, companyUsersLoading,
  }
})

export const useUserInvestorgStore = defineStore('userinvestorg', () => {
  const userStore = useUserStore()
  const user = computed(() => userStore.user)
  const investorg = useDocument(doc(database, 'investorgs', user.value.invorgid))
  const investorgUsers = useCollection(query(
    collection(database, 'users'),
    where('invorgid', '==', user.value.invorgid),
  ))
  const investorgLoading = computed(() => investorg.pending.value)
  const investorgUsersLoading = computed(() => investorgUsers.pending.value)

  return {
    investorg, investorgLoading, investorgUsers, investorgUsersLoading,
  }
})

export default {
  useUserStore, useUserContactStore, useUserCompanyStore, useUserInvestorgStore,
}
