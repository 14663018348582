import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useCollection } from 'vuefire'
import { addDoc, updateDoc, collection, query, where, doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore'
import { database } from '@/firebase'
import { useUserStore, useUserCompanyStore } from '@/users/stores'

export const useTemplateDialogsStore = defineStore('dialogs', () => {
  const clone = ref(false)
  const folder = ref(false)
  const template = ref(false)
  const move = ref(false)
  const del = ref(false)

  return { clone, folder, template, move, del }
})

export const useTemplatesStore = defineStore('templates', () => {
  const { user } = useUserStore()
  const companyStore = useUserCompanyStore()
  const selected = ref([])
  const templates = useCollection(query(
    collection(database, 'templates'),
    where('companyid', '==', user.companyid),
  ))
  const templatesLoading = computed(() => templates.pending.value)
  const fillTemplates = computed(() => {
    if (!companyStore.company || !companyStore.company.templates) return []
    return Object.values(companyStore.company.templates)
  })
  const selectedTemplates = computed(() => {
    if (selected.value.length === 0) return []
    return templates.value.filter((template) => selected.value.includes(template.id))
  })
  function createTemplate(data) {
    return addDoc(collection(database, 'templates'), { ...data, updated: new Date() })
  }
  function updateTemplate(id, data, options) {
    if (!data.companyid) {
      // This is a default template, so must clone this template to save it
      data.companyid = user.companyid
      addDoc(collection(database, 'templates'), { ...data, updated: new Date() })
        .then((docref) => {
          updateDoc(doc(database, 'companies', user.companyid),
            { [`templates.${id}`]: doc(database, 'templates', docref.id) })
        })
    }
    else setDoc(doc(database, 'templates', id), { ...data, updated: new Date() }, options)
  }
  function updateTemplates(ids, data, options) {
    return Promise.all(ids.map((id) => setDoc(doc(database, 'templates', id), data, options)))
  }
  function deleteTemplates(ids) {
    return Promise.all(ids.map((id) => deleteDoc(doc(database, 'templates', id))))
  }
  async function duplicateTemplate(id, dupname, dupid) {
    const docref = await getDoc(doc(database, 'templates', id))
    const data = docref.data()
    data.name = dupname
    data.updated = new Date()
    if (dupid) return setDoc(doc(database, 'templates', dupid), data)
    return addDoc(collection(database, 'templates'), data)
  }

  return {
    selected,
    templates,
    templatesLoading,
    fillTemplates,
    selectedTemplates,
    createTemplate,
    updateTemplate,
    updateTemplates,
    deleteTemplates,
    duplicateTemplate,
  }
})
