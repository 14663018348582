import axios from 'axios'
import { functionURL } from '@/utilities'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../firebase'

export async function getGoogleOAuthToken(params) {
  return axios.get(`${functionURL}googleuser-getGoogleOAuthToken`, { params })
}

export async function getGoogleGmail(params) {
  return httpsCallable(functions, 'gmail-initializeSync')(params)
}

export async function getGoogleContacts(params) {
  return axios.get(`${functionURL}googleuser-getGoogleContacts`, { params })
}

export async function getGoogleCalendars(params) {
  return axios.get(`${functionURL}googleuser-getGoogleCalendars`, { params })
}

export async function authenticateUser(params) {
  return axios.get(`${functionURL}googleuser-authenticateuser`, { params })
}

export async function scheduleEvent(params, body) {
  return axios.post(`${functionURL}googleuser-scheduleevent`, body, { params })
}

export async function sendGoogleMail(params, body) {
  return httpsCallable(functions, 'gmail-sendMessage')({ ...params, ...body })
}

export async function replyToThread(params, body) {
  return httpsCallable(functions, 'gmail-replyToThread')({ ...params, ...body })
}

export async function prepareGoogleUserForCloudFunction(scope, user) {
  return new Promise((resolve, reject) => {
    googleProvider.useGoogleLogin({
      flow: 'auth-code',
      scope,
      onError: reject,
      onSuccess: async (res) => {
        console.log('Logged in with Google', res)
        const response = await getGoogleOAuthToken({
          code: res.code,
          userid: user.id,
          email: user.email,
        })
        if (response.status === 200) { resolve() } else reject()
      },
    })()
  })
}
