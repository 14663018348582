/* eslint-disable import/order, import/no-named-as-default, import/no-unresolved */
import { createApp } from 'vue'
import { VueFire, VueFireAuth } from 'vuefire'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { firebaseApp, getCurrentUser } from './firebase'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
// import { md3 } from 'vuetify/blueprints'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labs from 'vuetify/labs/components'
import { turbofund } from './blueprint'
import { useExtensionInfoStore, useUserStore } from '@/stores'

require('./google')

const vuetify = createVuetify({
  components: { ...components, ...labs },
  directives,
  blueprint: turbofund,
})

const pinia = createPinia()

const app = createApp(App)
app.config.productionTip = false
app.use(VueFire, {
  firebaseApp,
  module: [
    VueFireAuth(),
  ],
})

app.use(pinia)
app.use(router)
app.use(vuetify)
app.mount('#app')

router.beforeEach(async (to) => {
  if (to.meta.requiresAuth) {
    const userStore = useUserStore()
    // Loading user if not yet loaded
    if (!userStore.user) {
      await userStore.userProfile()
    }
    const currentUser = await getCurrentUser()
    if (!currentUser) {
      return ({ name: 'landing' })
    }
  }
  return true
})

document.body.addEventListener('extensionstatus', (event) => {
  const extensionStore = useExtensionInfoStore()
  extensionStore.setExtensionInfo(event.detail)
})

document.body.addEventListener('extension:cookies', ({ detail }) => {
  const extensionStore = useExtensionInfoStore()
  const { cookies } = detail
  extensionStore.setCookies(cookies)
  console.log('Cookies from the oven', cookies)
})
