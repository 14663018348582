// eslint-disable-next-line import/no-mutable-exports
let functionURL = 'https://us-central1-entrepreneurssite.cloudfunctions.net/'
if (process.env.FIREBASE_FUNCTIONS === 'local') functionURL = 'http://localhost:5001/entrepreneurssite/us-central1/'

const prefixes = ['Mr.', 'Ms.', 'Dr.']
const suffixes = ['Jr.', 'Sr.', 'I', 'II', 'III', 'IV', 'V']

const usstates = [
  { name: 'Alabama', abbr: 'AL', code: 'us_al' },
  { name: 'Alaska', abbr: 'AK', code: 'us_ak' },
  { name: 'Arizona', abbr: 'AZ', code: 'us_az' },
  { name: 'Arkansas', abbr: 'AR', code: 'us_ar' },
  { name: 'California', abbr: 'CA', code: 'us_ca' },
  { name: 'Colorado', abbr: 'CO', code: 'us_co' },
  { name: 'Connecticut', abbr: 'CT', code: 'us_ct' },
  { name: 'Delaware', abbr: 'DE', code: 'us_de' },
  { name: 'District of Columbia', abbr: 'DC', code: 'us_dc' },
  { name: 'Florida', abbr: 'FL', code: 'us_fl' },
  { name: 'Georgia', abbr: 'GA', code: 'us_ga' },
  { name: 'Hawaii', abbr: 'HI', code: 'us_hi' },
  { name: 'Idaho', abbr: 'ID', code: 'us_id' },
  { name: 'Illinois', abbr: 'IL', code: 'us_il' },
  { name: 'Indiana', abbr: 'IN', code: 'us_in' },
  { name: 'Iowa', abbr: 'IA', code: 'us_ia' },
  { name: 'Kansas', abbr: 'KS', code: 'us_ks' },
  { name: 'Kentucky', abbr: 'KY', code: 'us_ky' },
  { name: 'Louisiana', abbr: 'LA', code: 'us_la' },
  { name: 'Maine', abbr: 'ME', code: 'us_me' },
  { name: 'Maryland', abbr: 'MD', code: 'us_md' },
  { name: 'Massachusetts', abbr: 'MA', code: 'us_ma' },
  { name: 'Michigan', abbr: 'MI', code: 'us_mi' },
  { name: 'Minnesota', abbr: 'MN', code: 'us_mn' },
  { name: 'Mississippi', abbr: 'MS', code: 'us_ms' },
  { name: 'Missouri', abbr: 'MO', code: 'us_mo' },
  { name: 'Montana', abbr: 'MT', code: 'us_mt' },
  { name: 'Nebraska', abbr: 'NE', code: 'us_ne' },
  { name: 'Nevada', abbr: 'NV', code: 'us_nv' },
  { name: 'New Hampshire', abbr: 'NH', code: 'us_nh' },
  { name: 'New Jersey', abbr: 'NJ', code: 'us_nj' },
  { name: 'New Mexico', abbr: 'NM', code: 'us_nm' },
  { name: 'New York', abbr: 'NY', code: 'us_ny' },
  { name: 'North Carolina', abbr: 'NC', code: 'us_nc' },
  { name: 'North Dakota', abbr: 'ND', code: 'us_nd' },
  { name: 'Ohio', abbr: 'OH', code: 'us_oh' },
  { name: 'Oklahoma', abbr: 'OK', code: 'us_ok' },
  { name: 'Oregon', abbr: 'OR', code: 'us_or' },
  { name: 'Pennsylvania', abbr: 'PA', code: 'us_pa' },
  { name: 'Rhode Island', abbr: 'RI', code: 'us_ri' },
  { name: 'South Carolina', abbr: 'SC', code: 'us_sc' },
  { name: 'South Dakota', abbr: 'SD', code: 'us_sd' },
  { name: 'Tennessee', abbr: 'TN', code: 'us_tn' },
  { name: 'Texas', abbr: 'TX', code: 'us_tx' },
  { name: 'Utah', abbr: 'UT', code: 'us_ut' },
  { name: 'Vermont', abbr: 'VT', code: 'us_vt' },
  { name: 'Virginia', abbr: 'VA', code: 'us_va' },
  { name: 'Washington', abbr: 'WA', code: 'us_wa' },
  { name: 'West Virginia', abbr: 'WV', code: 'us_wv' },
  { name: 'Wisconsin', abbr: 'WI', code: 'us_wi' },
  { name: 'Wyoming', abbr: 'WY', code: 'us_wy' },
]

const caprovs = [
  { name: 'Alberta', abbr: 'AB', code: 'ca_ab' },
  { name: 'British Columbia', abbr: 'BC', code: 'ca_bc' },
  { name: 'Manitoba', abbr: 'MB', code: 'ca_mb' },
  { name: 'New Brunswick', abbr: 'NB', code: 'ca_nb' },
  { name: 'Newfoundland/Labridor', abbr: 'NL', code: 'ca_nl' },
  { name: 'Nova Scotia', abbr: 'NS', code: 'ca_ns' },
  { name: 'Ontario', abbr: 'ON', code: 'ca_on' },
  { name: 'Quebec', abbr: 'QC', code: 'ca_qc' },
  { name: 'Prince Edward Island', abbr: 'PE', code: 'ca_pe' },
  { name: 'Saskatchewan', abbr: 'SK', code: 'ca_sk' },
  { name: 'Yukon', abbr: 'YT', code: 'ca_yt' },
]

const investRegions = [
  { name: 'United States', parent: null, id: 'usa' },
  { name: 'Mid-Atlantic', parent: 'usa', id: 'midatlantic' },
  { name: 'Delaware', parent: 'midatlantic', id: 'de' },
  { name: 'District of Columbia', parent: 'midatlantic', id: 'dc' },
  { name: 'Maryland', parent: 'midatlantic', id: 'md' },
  { name: 'New Jersey', parent: 'midatlantic', id: 'nj' },
  { name: 'Pennsylvania', parent: 'midatlantic', id: 'pa' },
  { name: 'Virginia', parent: 'midatlantic', id: 'va' },
  { name: 'West Virginia', parent: 'midatlantic', id: 'wv' },
  { name: 'New England', parent: 'usa', id: 'newengland' },
  { name: 'Connecticut', parent: 'newengland', id: 'ct' },
  { name: 'Maine', parent: 'newengland', id: 'me' },
  { name: 'Massachusetts', parent: 'newengland', id: 'ma' },
  { name: 'New Hampshire', parent: 'newengland', id: 'nh' },
  { name: 'Rhode Island', parent: 'newengland', id: 'ri' },
  { name: 'Vermont', parent: 'newengland', id: 'vt' },
  { name: 'New York', parent: 'usa', id: 'ny' },
  { name: 'Southeast', parent: 'usa', id: 'southeast' },
  { name: 'Alabama', parent: 'southeast', id: 'al' },
  { name: 'Arkansas', parent: 'southeast', id: 'ar' },
  { name: 'Florida', parent: 'southeast', id: 'fl' },
  { name: 'Georgia', parent: 'southeast', id: 'ga' },
  { name: 'Kentucky', parent: 'southeast', id: 'ky' },
  { name: 'Louisiana', parent: 'southeast', id: 'la' },
  { name: 'Mississippi', parent: 'southeast', id: 'mi' },
  { name: 'North Carolina', parent: 'southeast', id: 'nc' },
  { name: 'South Carolina', parent: 'southeast', id: 'sc' },
  { name: 'Tennessee', parent: 'southeast', id: 'tn' },
  { name: 'Mid-West', parent: 'usa', id: 'midwest' },
  { name: 'Indiana', parent: 'midwest', id: 'in' },
  { name: 'Illinois', parent: 'midwest', id: 'il' },
  { name: 'Iowa', parent: 'midwest', id: 'ia' },
  { name: 'Kansas', parent: 'midwest', id: 'ks' },
  { name: 'Michigan', parent: 'midwest', id: 'mi' },
  { name: 'Minnesota', parent: 'midwest', id: 'mn' },
  { name: 'Missouri', parent: 'midwest', id: 'mo' },
  { name: 'Nebraska', parent: 'midwest', id: 'ne' },
  { name: 'North Dakota', parent: 'midwest', id: 'nd' },
  { name: 'South Dokota', parent: 'midwest', id: 'sd' },
  { name: 'Ohio', parent: 'midwest', id: 'oh' },
  { name: 'Wisconsin', parent: 'midwest', id: 'wi' },
  { name: 'Texas', parent: 'usa', id: 'tx' },
  { name: 'Oklahoma', parent: 'tx', id: 'ok' },
  { name: 'Southwest', parent: 'usa', id: 'southwest' },
  { name: 'Arizona', parent: 'southwest', id: 'az' },
  { name: 'Colorado', parent: 'southwest', id: 'co' },
  { name: 'Nevada', parent: 'southwest', id: 'nv' },
  { name: 'New Mexico', parent: 'southwest', id: 'nm' },
  { name: 'Utah', parent: 'southwest', id: 'ut' },
  { name: 'California', parent: 'usa', id: 'ca' },
  { name: 'Northwest', parent: 'usa', id: 'northwest' },
  { name: 'Idaho', parent: 'northwest', id: 'id' },
  { name: 'Montana', parent: 'northwest', id: 'mt' },
  { name: 'Oregon', parent: 'northwest', id: 'or' },
  { name: 'Washington', parent: 'northwest', id: 'wa' },
  { name: 'Wyoming', parent: 'northwest', id: 'wy' },
  { name: 'Hawaii', parent: 'usa', id: 'hi' },
  { name: 'Alaska', parent: 'usa', id: 'ak' },
  { name: 'Canada', parent: null, id: 'canada' },
  { name: 'Alberta', parent: 'canada', id: 'ab' },
  { name: 'British Columbia', parent: 'canada', id: 'bc' },
  { name: 'Manitoba', parent: 'canada', id: 'mb' },
  { name: 'New Brunswick', parent: 'canada', id: 'nb' },
  { name: 'Newfoundland/Labridor', parent: 'canada', id: 'nl' },
  { name: 'Nova Scotia', parent: 'canada', id: 'ns' },
  { name: 'Ontario', parent: 'canada', id: 'on' },
  { name: 'Quebec', parent: 'canada', id: 'qc' },
  { name: 'Prince Edward Island', parent: 'canada', id: 'pe' },
  { name: 'Saskatchewan', parent: 'canada', id: 'sk' },
  { name: 'Yukon', parent: 'canada', id: 'yt' },
  { name: 'United Kingdom', parent: null, id: 'uk' },
  { name: 'Israel', parent: null, id: 'israel' },
  { name: 'Mexico', parent: null, id: 'mexico' },
  { name: 'Central America', parent: null, id: 'centralamer' },
  { name: 'South America', parent: null, id: 'southamer' },
  { name: 'Africa', parent: null, id: 'africa' },
  { name: 'South Africa', parent: null, id: 'southafrica' },
  { name: 'Europe', parent: null, id: 'europe' },
]

const investStages = [
  'Pre-Seed',
  'Seed',
  '+Revenue',
  '+Earnings',
]

const currencies = [
  { name: 'US $', abbr: 'USD' },
  { name: 'Canada $', abbr: 'CAD' },
]

const fundingRounds = [
  'Friends & Family',
  'Pre-Seed',
  'Seed',
  'Series A',
  'Series B',
  'Series C',
  'Series D',
  'Series E',
]

const fundingTypes = [
  'Convertible Note',
  'Equity',
  'SAFE',
  'Post SAFE',
  'Grant',
  'Loan',
]

const entityTypes = [
  'C Corporation',
  'S Corporation',
  'LLC',
  'Nonprofit',
  'Partnership',
  'Sole Proprietor',
]

const priorities = [
  { name: 'High', value: 'high' },
  { name: 'Medium', value: 'med' },
  { name: 'Low', value: 'low' },
  { name: 'None', value: null },
]

const pipelineStatus = [
  { name: 'Started', value: 'started' },
  { name: 'Screen', value: 'screen' },
  { name: 'First Pitch', value: 'firstpitch' },
  { name: 'Final Pitch', value: 'finalpitch' },
  { name: 'Due Diligence', value: 'diligence' },
  { name: 'Investment', value: 'investment' },
  { name: 'Declined', value: 'declined' },
]

const investorgTypes = [
  'Angel Group',
  'Venture Capital',
  'Accelerator',
  'Incubator',
  'Corporate VC',
  'Government Agency',
  'Family Office',
  'Venture Debt',
  'Bank',
]
const companyContactTypes = [
  { type: 'leader', name: 'Manager' , arrayname: 'leaders', label: 'Leaders' },
  { type: 'director', name: 'Board' , arrayname: 'directors', label: 'Board of Directors'},
  { type: 'employee', name: 'Employee', arrayname: 'employees', label: 'Employees' },
  { type: 'advisor', name: 'Advisor' , arrayname: 'advisors', label: 'Advisors' },
  { type: 'investor', name: 'Investor', arrayname: 'investors', label: 'Investors' },
]
const memberTypes = [
  { type: 'manager', name: 'Mngr', label: 'Manager' },
  { type: 'founder', name: 'Fndr', label: 'Founder' },
  { type: 'partner', name: 'Prtr', label: 'Partner' },
  { type: 'associate', name: 'Assc', label: 'Associate' },
  { type: 'investor', name: 'Invr', label: 'Investor' },
  { type: 'analyst', name: 'Anal', label: 'Analyst' },
  { type: 'assistant', name: 'Asst', label: 'Assistant' },
  { type: 'advisor', name: 'Advr', label: 'Advistor' },
  { type: 'finance', name: 'Fin', label: 'Finance' },
  { type: 'legal', name: 'Lgl', label: 'Legal' },
  { type: 'member', name: 'Mmbr', label: 'Member' },
]
const managerTypes = ['manager', 'founder', 'partner']
const supportTypes = ['associate', 'analyst', 'assistant', 'finance', 'legal']
const investorTypes = ['advisor', 'investor']

const impactFocus = [
  'Climate',
  'Environment',
  'Sustainability',
  'Women',
  'Diversity',
  'Health',
  'Regional',
  'Alumni',
  'Religion',
]
const templateUses = [
  { name: 'General', id: '' },
  { name: 'Short Pitch', id: 'short-pitch' },
  { name: 'Long Pitch', id: 'long-pitch' },
  { name: 'Fundraise Offering', id: 'fundraise-offering' },
  { name: 'Terms Offered', id: 'terms-offered' },
  { name: 'Market Traction', id: 'market-traction' },
  { name: 'Combo Market & Size', id: 'combo-market-size' },
  { name: 'Combo Company Overview & Market', id: 'combo-overview-market' },
  { name: 'Combo Customer Problem & Solution', id: 'combo-problem-solution' },
  { name: 'Combo Problem & Solution & Traction', id: 'combo-problem-solution-traction' },
  { name: 'Combo Product & Business Model', id: 'combo-product-businessmodel' },
  { name: 'Combo Competition & Advantage', id: 'combo-competition-advantage' },
]

const newUserTasks = [
  {
    title: 'Install: TurboFund Extension',
    type: 'install',
    priority: 'high',
    id: 'install_extension',
    index: 1,
    target: 'https://chromewebstore.google.com/detail/plasmo-turbofund/lkbdcdaldmeaebfbmndpmncnlgjfhohd',
    description: 'Install the TurboFund extension to enable autofill, tracking and importing from LinkedIn and investment platforms.',
  },
  {
    title: 'Finish: Personal Profile',
    type: 'edit',
    priority: 'high',
    id: 'personal_profile',
    index: 2,
    route: '/ent/home/myprofile',
    description: 'Complete your personal profile with optional import from you LinkedIn profile',
  },
  {
    title: 'Sync: Your Company Gmail',
    type: 'sync',
    priority: 'high',
    id: 'sync_gmail',
    index: 3,
    route: '/ent/emails',
    description: 'Allow TurboFund to read/write emails with your gmail account',
  },
  {
    title: 'Sync: Google Calendar',
    type: 'sync',
    priority: 'high',
    id: 'sync_calendars',
    index: 4,
    route: '/ent/events',
    description: 'Allow TurboFund to read/write calendar events with your Google account',
  },
  {
    title: 'Sync: Google Contacts/LinkedIn Connections',
    type: 'sync',
    priority: 'med',
    id: 'sync_contacts',
    index: 5,
    route: '/ent/contacts',
    description: 'Allow TurboFund to read your Google contacts and LinkedIn connections',
  },
]

const newCompanyTasks = [
  {
    title: 'Enter Business Plan: Summary',
    type: 'edit',
    priority: 'high',
    id: 'bplan_summary',
    index: 1,
    route: '/ent/businessplan#summary',
    description: 'Enter: Company Overview, Customer Problem, Product/Service, Revenue Model',
  },
  {
    title: 'Enter Business Plan: Market',
    type: 'edit',
    priority: 'high',
    id: 'bplan_market',
    index: 2,
    route: '/ent/businessplan#market',
    description: 'Enter: Target Customer, Current Customers, Target Market, TAM, GTM',
  },
  {
    title: 'Enter Business Plan: Competition',
    type: 'edit',
    priority: 'high',
    id: 'bplan_competition',
    index: 3,
    route: '/ent/businessplan#competition',
    description: 'Enter: Competition, Competitive Advantage, Competitor Analysis',
  },
  {
    title: 'Enter Business Plan: Team',
    type: 'edit',
    priority: 'high',
    id: 'bplan_team',
    index: 4,
    route: '/ent/businessplan#team',
    description: 'Enter: Team Overview, Import Team from Linkedin',
  },
  {
    title: 'Enter Business Plan: Finances',
    type: 'edit',
    priority: 'high',
    id: 'bplan_finances',
    index: 5,
    route: '/ent/businessplan#finances',
    description: 'Financial Projections, Valuation Explanation, Use of Funds, Exit Strategy',
  },
  {
    title: 'Upload: Pitch Deck',
    type: 'upload',
    priority: 'high',
    id: 'pitch_deck',
    index: 6,
    route: '/ent/businessplan#pitchdeck',
    description: 'Upload: Investor Pitch Deck',
  },
  {
    title: 'Import: Investor Pipeline',
    type: 'import',
    priority: 'high',
    id: 'investor_pipeline',
    index: 7,
    route: '/ent/applications',
    description: 'Import: previous funding applications from Gust, Dealum, SparkXYZ and spreadsheet',
  },
  {
    title: 'Import: CAP Table',
    type: 'import',
    priority: 'high',
    id: 'cap_table',
    index: 8,
    route: '/ent/captable',
    description: 'Import: CAP Table from spreadsheet',
  },
  {
    title: 'Finish: Company Profile',
    type: 'edit',
    priority: 'med',
    id: 'company_profile',
    index: 9,
    route: '/ent/home/mycompany',
    description: 'Complete your company profile',
  },
]

const userTasksTooltips = {
  1: {
    title: 'Install TurboFund Chrome Extension',
    content: `<b>Install our Chrome extension to autofill the majority of your investor applications</b> >>
    <b>The extension also pulls in fundraising data from key sites like Gust, Dealum, and Sparkxyz</b> >>
    `,
    timeToComplete: '2 Minutes',
    comingSoon: 'We’re working on an AI-powered version of our extension which will turbo charge your fundraising process across multiple platforms.',
  },
  2: {
    title: 'Personal Profile',
    content: `Much of this was auto-filled when you created your account.
    Please take a moment to review and ensure your information is up to date.`,
    timeToComplete: '2 Minutes',
  },
  3: {
    title: 'Sync Your Company Gmail',
    content: `<b>Sync your Gmail</b>with TurboFund to seamlessly integrate and centralize every 
    past and future email exchanged with investors into your CRM and Investor Pipeline. >>
    <b>Why? Every investor conversation you have in Gmail automatically becomes a trackable deal 
    in your Investor Pipeline.</b> Imagine having a full-time assistant who not only keeps all your
    communications organized in one place but also ensures that no opportunity slips through the cracks.`,
    timeToComplete: '2 Minutes',
  },
  4: {
    title: 'Sync Your Google Calendar',
    content: `<b>Sync your Google Calendar</b> to seamlessly create and track all 
    investor-related meetings across both your Google Calendar and TurboFund.`,
    timeToComplete: '2 Minutes',
  },
  5: {
    title: 'Sync Your Contacts',
    content: `<b>Sync your Google Contacts</b> with TurboFund to automatically update your
    CRM and Investor Pipeline with your latest VC connections. >>
    <b>Sync your LinkedIn connections</b> to pull in your team’s contacts and discover which
    investors are already in your network.`,
    timeToComplete: '2 Minutes',
  },
  8: {
    title: 'Enter Business Plan: Summary',
  },
}

const companyTasksTooltips = {
  1: {
    title: 'Enter Business Plan: Summary',
    content: `<b>Consider this a one-time effort that will dramatically 
    enhance your ability to share exactly what investors need.</b> >> 
    <b>Enter Summary:</b><br/>
    Company Overview, Customer Problems, Product/Service, and Revenue Model`,
    timeToComplete: '30 +/-  minutes',
    comingSoon: 'We’re working on an AI-powered solution to help automate this process, making it even easier to get started.',
  },
  2: {
    title: 'Enter Business Plan: Market',
    content: `<b>Consider this a one-time effort that will dramatically 
    enhance your ability to share exactly what investors need.</b> >> 
    <b>Enter Market:</b><br/>
    Target Customer, Current Customers, Target Market, TAM, GTM`,
    timeToComplete: '30 +/-  minutes',
    comingSoon: 'We’re working on an AI-powered solution to help automate this process, making it even easier to get started.',
  },
  3: {
    title: 'Enter Business Plan: Competition',
    content: `<b>Consider this a one-time effort that will dramatically 
    enhance your ability to share exactly what investors need.</b> >> 
    <b>Enter Competition:</b><br/>
    Competition, Competitive Advantage, Competitor Analysis`,
    timeToComplete: '30 +/-  minutes',
    comingSoon: 'We’re working on an AI-powered solution to help automate this process, making it even easier to get started.',
  },
  4: {
    title: 'Enter Business Plan: Team',
    content: `<b>Consider this a one-time effort that will dramatically 
    enhance your ability to share exactly what investors need.</b> >> 
    <b>Enter Team:</b><br/>
    Team Overview, Import Team from Linkedin`,
    timeToComplete: '30 +/-  minutes',
    comingSoon: 'We’re working on an AI-powered solution to help automate this process, making it even easier to get started.',
  },
  5: {
    title: 'Enter Business Plan: Finances',
    content: `<b>Consider this a one-time effort that will dramatically 
    enhance your ability to share exactly what investors need.</b> >> 
    <b>Enter Finances:</b><br/>
    Financial Projections, Valuation Explanation, Use of Funds, Exit Strategy`,
    timeToComplete: '30 +/-  minutes',
  },
  6: {
    title: 'Upload Your Pitch Deck',
    content: `We suggest using google slides so you can get the most out of our system,
     like live  updates, etc. But you can also attach a powerpoint file or a PDF.`,
    timeToComplete: '2  minutes',
  },
  7: {
    title: 'Import Previous Funding Applications',
    content: `<b>We support:</b><br/>
    - Gust<br/>
    - Dealum<br/>
    - Spark XYZ<br/>`,
  },
  8: {
    title: 'Keep Your CAP Table Up-to-Date',
    content: `Easily input and update key data points in your CAP 
    Table to keep your ownership structure clear and up-to-date.`,
    comingSoon: 'Import from a Google Sheets template & Integration with Carta',
  },
  9: {
    title: 'Finish Your Company Profile',
    content: `Much of this was auto-filled when you created your account. 
    Please take a moment to review and ensure your information is up to date.`,
    timeToComplete: '2 minutes',
  },
}

const contactMergeTags = [
  { title: 'Name', tag: 'contact.fullName', description: 'Full name' },
  { title: 'First name', tag: 'contact.firstName', description: 'First (given) name' },
  { title: 'Last name', tag: 'contact.lastName', description: 'Last (family) name' },
  { title: 'Title', tag: 'contact.title', description: 'Current title' },
  { title: 'Company', tag: 'contact.company', description: 'Employer name' },
  { title: 'Invest Org', tag: 'contact.investorg', description: 'Investor organization name' },
  { title: 'Location', tag: 'contact.location', description: 'Location where contact live' },
  { title: 'Email', tag: 'contact.email', description: 'Work email' },
  { title: 'Phone', tag: 'contact.phone', description: 'Work phone' },
  { title: 'LinkedIn', tag: 'contact.linkedin', description: 'Linkedin profile page' },
  { title: 'Twitter', tag: 'contact-twitter', description: 'Twitter handle' },
]

/* eslint-disable max-len */
const businessplanMergeTags = [
  {
    title: 'Pitchdeck URL',
    tag: 'businessplan.pitchdeck',
    description: "Link address to the company's 'public' pitch deck",
  },
  {
    title: 'Market Size',
    tag: 'businessplan.market-size',
    description: 'The percentage of the total revenue or sales in a market that a company\'s business makes up.',
  },
  {
    title: 'Company overview',
    tag: 'businessplan.company-overview',
    description: 'Overview of your company and what makes your company special.',
  },
  {
    title: 'Product/Service',
    tag: 'businessplan.product-service',
    description: 'Describe the product or service that you will sell and how it solves the customer problem, listing the main value proposition for each product/service.',
  },
  { title: 'Customer', tag: 'businessplan.customer', description: 'Who buys your product or service?' },
  {
    title: 'Customer problem',
    tag: 'businessplan.customer-problem',
    description: 'What customer problem does your product and/or service solve?',
  },
  {
    title: 'Target market',
    tag: 'businessplan.target-market',
    description: 'Define the important geographic, demographic, and/or psychographic characteristics of the market within which your customer segments exist.',
  },
  {
    title: 'Customer segments',
    tag: 'businessplan.customer-segments',
    description: 'Outline your targeted customer segments. These are the specific subsets of your target market that you will focus on to gain traction.',
  },
  {
    title: 'Business model',
    tag: 'businessplan.business-model',
    description: 'What strategy will you employ to build, deliver, and retain company value (profits)?',
  },
  {
    title: 'Sales and Market Strategy',
    tag: 'businessplan.sales-strategy',
    description: 'What is your customer acquisition and retention strategy? Detail how you will promote, sell and create customer loyalty for your products and services.',
  },
  {
    title: 'Competition',
    tag: 'businessplan.competition',
    description: 'Describe the competitive landscape and your competitors’ strengths and weaknesses. If direct competitors don’t exist, describe the existing alternatives.',
  },
  {
    title: 'Competitive advantage',
    tag: 'businessplan.competitive-advantage',
    description: 'What is your company’s competitive or unfair advantage? This can include patents, first mover advantage, unique expertise, or proprietary processes/technology.',
  },
  {
    title: 'Use of Funds',
    tag: 'businessplan.use-of-funds',
    description: 'How will the current funding round be used?',
  },
  {
    title: 'Exit strategy',
    tag: 'businessplan.exit-strategy',
    description: 'What is your strategy to exit (acquistion/IPO)?',
  },
  {
    title: 'Valuation',
    tag: 'businessplan.valuation',
    description: 'How do you justify your current valuation?',
  },
  {
    title: 'Team overview',
    tag: 'businessplan.team',
    description: 'Who are the key team members of your company and why?',
  },
  {
    title: 'Elevator pitch',
    tag: 'businessplan.oneliner',
    description: 'In one sentence try to pitch your startup',
  },
  {
    title: 'Social impact',
    tag: 'businessplan.social-impact',
    description: 'Social impact of your company',
  },
  {
    title: 'Environmental impact',
    tag: 'businessplan.env-impact',
    description: 'Environmental impact of your company',
  },
]
/* eslint-enable max-len */

const fundraiseMergeTags = [
  {
    title: 'Name',
    tag: 'fundraise.name',
    description: 'Name for this funding round',
  },
  {
    title: 'Funding stage',
    tag: 'fundraise.round',
    description: 'Stage of round',
  },
  {
    title: 'Current offering type',
    tag: 'fundraise.type',
    description: 'Type of round (equity, convertible note, SAFE ...)',
  },
  {
    title: 'Open date',
    tag: 'fundraise.opendate',
    description: 'Date when this funding round was opened',
  },
  {
    title: 'Close date',
    tag: 'fundraise.closedate',
    description: 'Expected date to complete the funding round',
  },
  {
    title: 'Target amount',
    tag: 'fundraise.targetamount',
    description: 'Total amount to raise with this round',
  },
  {
    title: 'Valuation',
    tag: 'fundraise.valuation',
    description: 'Premoney valuation offered for this round',
  },
]

const companyMergeTags = [
  { title: 'Name', tag: 'company.name', description: 'Company simple name' },
  { title: 'Legal name', tag: 'company.legalname', description: 'Company registered name' },
  { title: 'Date founded', tag: 'company.filedate', description: 'Registration date or date when founded' },
  { title: 'Location', tag: 'company.loc', description: 'Location of the company' },
  { title: 'Address', tag: 'company.address', description: 'Company mailing address' },
  { title: 'Website', tag: 'company.website', description: 'URL for company website' },
  { title: 'Email', tag: 'company.email', description: 'Email address to contact company' },
  { title: 'Phone', tag: 'company.phone', description: 'Phone number to contact company' },
  { title: 'Description', tag: 'company.description', description: 'Description of company in one paragraph' },
  { title: 'Linkedin', tag: 'company.linkedin', description: 'URL for company page on LinkedIn' },
  { title: 'Twitter', tag: 'company.twitter', description: 'URL for company twitter page' },
  { title: 'Number of employees', tag: 'company.numemployees', description: 'Number of full time employees' },
  { title: 'Yearly revenue', tag: 'company.yearlyrevenue', description: 'Current revenue rate this year' },
  { title: 'Monthly expenses', tag: 'company.monthlyburn', description: 'Current monthly burn rate' },
  {
    title: 'Total amount past funding',
    tag: 'company.totalfunding',
    description: 'Total amount raised before this raise',
  },
  { title: 'Total amount of grants', tag: 'company.totalgrants', description: 'Total amount of all grants received' },
]

const investorgMergeTags = [
  { title: 'Name', tag: 'investorg.name', description: 'Organization name' },
  { title: 'Location', tag: 'investorg.loc', description: 'Headquarters location' },
  { title: 'Address', tag: 'investorg.address', description: 'Organization mailing address' },
  { title: 'Website', tag: 'investorg.website', description: 'URL for organization website' },
  { title: 'Email', tag: 'investorg.email', description: 'Email address to contact organization' },
  { title: 'Phone', tag: 'investorg.phone', description: 'Phone number to contact organization' },
  { title: 'Description', tag: 'investorg.description', description: 'Description of organization in one paragraph' },
  { title: 'Linkedin', tag: 'investorg.linkedin', description: 'URL for organization page on LinkedIn' },
  { title: 'Twitter', tag: 'investorg.twitter', description: 'URL for organization twitter page' },
  { title: 'Number of members', tag: 'investorg.nummembers', description: 'Number of members' },
]

const applicationMergeTags = [
  { title: 'Name', tag: 'Application.name', description: 'Application name' },
]

const simplifyLastName = (lastname) => {
  let name = lastname.replace(/\{.*\}/, '').split(',')[0]
  // Remove chinese and emoji characters
  name = name.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
  name = name.replace(/([\u4e00-\u9fff\u3400-\u4dbf\ufa0e\ufa0f\ufa11\ufa13\ufa14\ufa1f\ufa21\ufa23\ufa24\ufa27\ufa28\ufa29\u3006\u3007]|[\ud840-\ud868\ud86a-\ud879\ud880-\ud887][\udc00-\udfff]|\ud869[\udc00-\udedf\udf00-\udfff]|\ud87a[\udc00-\udfef]|\ud888[\udc00-\udfaf])([\ufe00-\ufe0f]|\udb40[\udd00-\uddef])?/g, '')
  name = name.replace(/\(([^()]*)\)/g, '')
  name = name.replace(/"([^"]*)"/g, '')
  name = name.replace(/ [Pp]\.?[Hh]\.?[Dd]\.?.*/, '')
  name = name.replace(/ [Cc][FfPp][Aa].*/, '')
  name = name.replace(/ [Mm]\.?[Dd]\.?.*/, '')
  name = name.replace(/ [A-Z][A-Z].*/, '') // Remove certifications
  name = name.replace(/  /g, ' ')
  return name.trim()
}

const simplifyName = (fullname) => {
  let name = this.simplifyLastName(fullname)
  name = name.replace(/^The Rev[a-z]* /, '')
  // name = name.replace(/\x3400-\x4DB5\x4E00-\x9FCB\xF900-\xFA6A/g,'')
  return name
}

const simplifyFirstName = (firstname) => {
  let name = firstname.replace(/\{.*\}/, '').split(',')[0]
  // Remove chinese and emoji characters
  name = name.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
  name = name.replace(/([\u4e00-\u9fff\u3400-\u4dbf\ufa0e\ufa0f\ufa11\ufa13\ufa14\ufa1f\ufa21\ufa23\ufa24\ufa27\ufa28\ufa29\u3006\u3007]|[\ud840-\ud868\ud86a-\ud879\ud880-\ud887][\udc00-\udfff]|\ud869[\udc00-\udedf\udf00-\udfff]|\ud87a[\udc00-\udfef]|\ud888[\udc00-\udfaf])([\ufe00-\ufe0f]|\udb40[\udd00-\uddef])?/g, '')
  name = name.replace(/\(([^()]*)\)/g, '')
  name = name.replace(/"([^"]*)"/g, '')
  name = name.replace(/^The Rev[a-z]* /, '')
  name = name.replace(/  /g, ' ')
  return name.trim()
}

const memberTypeFromTitle = (title) => {
  let type
  if (!title) {
    type = null
  } else {
    const ltitle = title.toLowerCase()
    if (ltitle.includes('intern')
      || ltitle.includes('student')
      || ltitle.includes('candidate')) {
      type = null
    } else if (ltitle.includes('founder')
      || ltitle.includes('founding')) {
      type = 'founder'
    } else if (ltitle.includes('assistant')
      || ltitle.includes('administrator')
      || ltitle.includes('residence')) {
      type = 'assistant'
    } else if (ltitle.includes('advisor')) {
      type = 'advisor'
    } else if (title.includes('CFO')
      || title.includes('Tax')
      || ltitle.includes('finance')
      || ltitle.includes('treasurer')
      || ltitle.includes('account')
      || ltitle.includes('controller')) {
      type = 'finance'
    } else if (ltitle.includes('manager')
      || ltitle.includes('managing')
      || ltitle.includes('general')
      || ltitle.includes('principal')
      || title.includes('Head')
      || title.includes('Lead')
      || ltitle.includes('president')
      || title.includes('VP')
      || ltitle.includes('team')
      || ltitle.includes('officer')
      || ltitle.includes('chair')
      || ltitle.includes('executive')
      || ltitle.includes('chief')
      || ltitle.includes('director')
      || title.match(/C[A-Z]O/)) {
      type = 'manager'
    } else if (ltitle.includes('partner')
      || title.includes('VC')
      || ltitle.includes('venture capital')) {
      type = 'partner'
    } else if (ltitle.includes('legal')
      || ltitle.includes('counsel')
      || ltitle.includes('laywer')
      || ltitle.includes('attorney')) {
      type = 'legal'
    } else if (ltitle.includes('associate')
      || ltitle.includes('fellow')
      || ltitle.includes('engineer')
      || ltitle.includes('research')
      || ltitle.includes('operations')) {
      type = 'associate'
    } else if (ltitle.includes('analyst')
      || ltitle.includes('specialist')) {
      type = 'analyst'
    } else if (ltitle.includes('invest')) {
      type = 'investor'
    } else if (ltitle.includes('coordinator')) {
      type = 'assistant'
    } else {
      type = null
    }
  }
  return type
}

const contactID = (fullname) => {
  let name = fullname.toLowerCase().trim()
  name = name.replace(/\./g, '') // Remove periods
  name = name.replace(/\s/g, '_') // Convert space to _ characters
  name = name.replace(/\//g, '_') // Convert backslash to _ characters
  return name
}

const companyID = (companyname) => {
  let name = String(companyname).toLowerCase().trim()
  name = name.replace(/\(.*\)/, '') // Remove anything in parenthesis
  name = name.replace(/\s-\s/g, '-')
  name = name.replace(/\.com$/, '')
  name = name.replace(/\.org$/, '')
  name = name.replace(/\s/g, '_') // Convert space to _ character
  name = name.replace(/\.$/, '')
  name = name.replace(/,/g, '_') // Convert comma to _ character
  name = name.replace(/\//g, '_') // Convert backslash to _ character
  name = name.replace(/__/g, '_')
  name = name.replace(/_$/, '')
  return name
}

const extractDomain = (website) => {
  let domain = website.replace('http://', '')
  domain = domain.replace('https://', '')
  domain = domain.replace('www.', '')
  domain = domain.replace(/\/.*$/, '')
  return domain.toLowerCase()
}

const normalizePhoneNumber = (phone) => {
  let p = String(phone)
  p = p.trim()
  if (p[0] === '+') {
    if (p[1] === '1') {
      let praw = p.substring(3, p.length).replaceAll(/[^0-9]/g,'')
      if (praw.length === 10) {
        p = '(' + praw.substring(0, 3) + ') ' + praw.substring(3, 6) + '-' + praw.substring(6, 10)
      }
    }
  } else {
    let praw = p.replaceAll(/[^0-9]/g,'')
    if (praw.length === 10) {
      p = '(' + praw.substring(0, 3) + ') ' + praw.substring(3, 6) + '-' + praw.substring(6, 10)
    }
  }
  return p
}

const normalizeLinkedinURL = (liurl) => {
  let url = liurl
  url = url.replace(/\?.*/, '')
  url = url.replace(/\/overlay\/.*$/, '')
  url = url.replace(/\/about\/?$/, '')
  url = url.replace(/https?:\/\/.*linkedin\.com/, 'https://www.linkedin.com')
  url = url.replace(/\/$/, '')
  return url
}

const normalizeTwitterURL = (twurl) => {
  let url = twurl
  url = url.replace(/\?.*/, '')
  url = url.replace(/https?:\/\/.*twitter\.com/, 'https://twitter.com')
  url = url.replace(/\/$/, '')
  return url
}

const normalizeFacebookURL = (fburl) => {
  let url = fburl
  url = url.replace(/\?.*/, '')
  url = url.replace(/https?:\/\/.*facebook\.com/, 'https://www.facebook.com')
  url = url.replace(/\/$/, '')
  return url
}

const simplifyCompanyName = (companyname) => {
  let name = companyname.trim()
  name = name.replace(/,.*/, '') // anything after a comma
  name = name.replace(/ LLC$/, '') // remove LLC
  name = name.replace(/ LP$/, '') // remove LP
  name = name.replace(/ I[nN][cC]?\.$/, '') // remove Inc or Inc. etc
  name = name.replace(/ L[tT][dD]?\.$/, '') // remove Ltd or Ltd.
  name = name.replace(/ \(.*\)/, '') // Remove anything in parenthesis
  name = name.replace(/\//g, ' ')
  return name
}

const scaledAmount = (amount) => {
  if (amount == null) return null
  if (amount > 0) {
    const famount = Math.floor(Math.log(amount) / Math.log(1000))
    const amnt = `${(amount / 1000 ** famount).toFixed(1) * 1}${['', 'K', 'M', 'B'][famount]}`
    return amnt
  }
  if (amount < 0) {
    const namount = -amount
    const famount = Math.floor(Math.log(namount) / Math.log(1000))
    const amnt = `-${(namount / 1000 ** famount).toFixed(1) * 1}${['', 'K', 'M', 'B'][famount]}`
    return amnt
  }
  return '0'
}

const todayISODate = () => {
  const now = new Date()
  return new Date(now.getTime() - now.getTimezoneOffset() * 1000 * 60).toISOString().substring(0, 10)
}

// Below parses the passed string to extract name and email address in one of the formats below
// 1. John Miranda <john@gmail.com>
// 2. john@gmail.com
const parseEmailPerson = (person) => {
  const p = person.trim()

  const extract = { name: '', email: '' }
  const emails = p.match(/[^@<\s]+@[^@\s>]+/g)
  if (emails) extract.email = emails[0]
  const names = p.split(/\s+/)
  if (names.length > 1) {
    names.pop()
    extract.name = names.join(' ').replace(/"/g, '')
  }
  return extract
}

const copyToClipboard = (textToCopy) => navigator.clipboard.writeText(textToCopy)

const isHtml = (input) => {
  return Boolean(/<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input))
}

export {
  functionURL,
  prefixes,
  suffixes,
  usstates,
  caprovs,
  currencies,
  fundingRounds,
  fundingTypes,
  entityTypes,
  priorities,
  pipelineStatus,
  investorgTypes,
  companyContactTypes,
  memberTypes,
  managerTypes,
  supportTypes,
  investorTypes,
  impactFocus,
  investRegions,
  investStages,
  templateUses,
  newCompanyTasks,
  newUserTasks,
  companyTasksTooltips,
  userTasksTooltips,
  contactMergeTags,
  businessplanMergeTags,
  fundraiseMergeTags,
  companyMergeTags,
  investorgMergeTags,
  applicationMergeTags,
  simplifyLastName,
  simplifyName,
  simplifyFirstName,
  memberTypeFromTitle,
  contactID,
  companyID,
  extractDomain,
  normalizePhoneNumber,
  normalizeLinkedinURL,
  normalizeTwitterURL,
  normalizeFacebookURL,
  simplifyCompanyName,
  scaledAmount,
  todayISODate,
  parseEmailPerson,
  copyToClipboard,
  isHtml,
}
