import { mdi } from 'vuetify/iconsets/mdi'

export const turbofund = {
  defaults: {
    VAppBar: {
      flat: true,
    },
    VAutocomplete: {
      variant: 'filled',
    },
    VBanner: {
      color: 'primary',
    },
    // VBtn: {
    //   color: 'surface',
    //   rounded: 'lg'
    // },
    VBtnGroup: {
      rounded: 'xl',
      VBtn: {
        rounded: null,
      },
    },
    VCard: {
      rounded: 'sm',
      elevation: 0,
      width: '100%',
    },
    VCheckbox: {
      color: 'secondary',
    },
    VChip: {
      rounded: 'sm',
    },
    VCombobox: {
      variant: 'filled',
    },
    VNavigationDrawer: {
      // VList: {
      //   nav: true,
      //   VListItem: {
      //     rounded: 'xl',
      //   },
      // },
    },
    VSelect: {
      variant: 'filled',
    },
    VSlider: {
      color: 'primary',
    },
    VTabs: {
      color: 'primary',
    },
    VTextarea: {
      variant: 'filled',
    },
    VTextField: {
      variant: 'filled',
    },
  },
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi,
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#1565C0',
          secondary: '#b4b0bb',
          tertiary: '#f5f5f5',
          error: '#b3261e',
          surface: '#ffffff',
          background: '#fafafa',
          highPriority: '#ffb5b5',
          medPriority: '#ffddb6',
          lowPriority: '#faf9b3',
          NonePriority: '#000000',
        },
      },
    },
  },
}
