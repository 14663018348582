import { defineStore } from 'pinia'
import { database } from '@/firebase'
import { doc } from 'firebase/firestore'
import { useDocument } from 'vuefire'
import { useUserStore } from '@/users/stores'

export const useGoogleDataStore = defineStore('google-data', () => {
  const { user } = useUserStore()
  const googleData = useDocument(doc(database, 'google', user.id))

  return { googleData }
})

export default useGoogleDataStore
