import { computed } from 'vue'
import { collection } from 'firebase/firestore'
import { database } from '@/firebase'
import { defineStore } from 'pinia'
import { useCollection } from 'vuefire'
import { useUserStore } from '@/users/stores'

export const useTasksStore = defineStore('tasks', () => {
  const { user } = useUserStore()
  const tasks = useCollection(collection(database, 'companies', user.companyid, 'tasks'))
  const tasksLoading = computed(() => tasks.pending.value)
  const userTasks = computed(() => (tasksLoading.value
    ? [] : tasks.value
      .filter((t) => t.userid === user.id)
      .sort((a, b) => (a.index && b.index ? a.index - b.index : a.created - b.created))))
  const companyTasks = computed(() => (tasksLoading.value || !user.companyid
    ? [] : tasks.value
      .filter((t) => t.companyid === user.companyid)
      .sort((a, b) => (a.index && b.index ? a.index - b.index : a.created - b.created))))
  const investorgTasks = computed(() => (tasksLoading.value || !user.invorgid
    ? [] : tasks.value
      .filter((task) => task.invorgid === user.invorgid)
      .sort((a, b) => a.created - b.created)))

  const checkTaskOpen = (taskId) => computed(() => {
    return tasks.value.some((task) => task.id === taskId)
  })
  return { tasks, tasksLoading, userTasks, companyTasks, investorgTasks, checkTaskOpen }
})

export default useTasksStore
