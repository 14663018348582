import { v4 } from 'uuid'
import { ref } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import {
  moveDriveFile,
  getDriveFileMetadata,
  listDriveFiles,
  trashDriveFile,
  renameDriveFile,
  uploadDriveFile,
  createDriveFolder,
  createSharedDrive,
} from '@/services/googledrive'
import { useUserCompanyStore } from '@/users/stores'

export const useGoogleDriveStore = defineStore('googleDrive', () => {
  const driveFolder = ref(null)
  const driveFiles = ref([])
  const folderCrumbs = ref([])
  const driveLoaded = ref(false)
  const pending = ref(false)

  function setDriveFolder(value) {
    const companyStore = useUserCompanyStore()
    const folder = { ...value }
    if (value.name === 'Drive') folder.name = companyStore.company.name
    driveFolder.value = folder
    folderCrumbs.value = [folder]
  }
  function gotoDriveFolder(value) {
    const index = folderCrumbs.value.findIndex((folder) => folder.id === value.id)
    if (index < 0) folderCrumbs.value.push(value)
    else folderCrumbs.value = folderCrumbs.value.slice(0, index + 1)
  }
  function updateByIndex(value, i) {
    const index = driveFiles.value.findIndex((file) => file.index === i)
    if (index >= 0) driveFiles.value[index] = value
  }
  function updateFile(value) {
    const index = value.value ? driveFiles.value.findIndex((file) => file.value === value.value)
      : driveFiles.value.findIndex((file) => file.id === value.id)
    if (index < 0) console.log('failed to find file to update', value)
    else driveFiles.value[index] = value
  }
  function addFile(value) {
    driveFiles.value.splice(0, 0, value)
  }
  async function initDriveFiles(folderId) {
    const response = await listDriveFiles({ driveId: folderId })
    if (response.status === 200) {
      driveFiles.value = response.data
      driveLoaded.value = true
    } else console.log(response.status, response.data)
  }
  async function initDriveFolder(folderId) {
    if (driveLoaded.value) {
      setDriveFolder(folderCrumbs.value[0])
      return
    }
    const response = await getDriveFileMetadata({ fileId: folderId })
    if (response.status === 200) {
      console.log("Google metadata", response.data)
      setDriveFolder(response.data)
      await initDriveFiles(folderId)
    } else console.log(response.status, response.data)
  }
  async function createCompanyDrive(company) {
    const response = await createSharedDrive({
      name: company.name,
      id: v4(),
    })
    if (response.status === 200) return response.data
    return null
  }
  async function createNewFolder(args) {
    pending.value = true
    const file = {
      name: args.foldername,
      parents: [args.parentId],
      trashed: false,
      properties: { owner: args.owner },
      mimeType: 'application/vnd.google-apps.folder',
      iconLink: 'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.folder+48',
      modifiedTime: Date.now(),
      index: driveFiles.value.length,
    }
    addFile(file)
    await createDriveFolder({
      name: args.foldername,
      parentId: args.parentId,
      owner: args.owner,
    })
    pending.value = false
  }
  async function uploadFile(args) {
    pending.value = true
    const index = driveFiles.value.length
    const file = {
      name: args.filename,
      parents: [args.parentId],
      trashed: false,
      properties: { owner: args.owner },
      modifiedTime: Date.now(),
      mimeType: '',
      index,
    }
    addFile(file)
    const response = await uploadDriveFile({
      filename: args.filename,
      type: args.filetype,
      parentId: args.parentId,
      owner: args.owner,
    })
    if (response.status === 200) {
      const filedata = response.data
      updateByIndex(filedata, index)
      pending.value = false
      return filedata
    }
    pending.value = false
    return null
  }
  async function renameFile(file) {
    pending.value = true
    updateFile(file)
    const owner = (file.properties && file.properties.owner)
      ? file.properties.owner
      : file.lastModifyingUser.displayName
    const response = await renameDriveFile({
      filename: file.name,
      fileId: file.id,
      owner,
    })
    if (response.status === 200) {
      console.log('File renamed', file.name)
    }
    pending.value = false
  }
  async function moveDriveFiles(args) {
    pending.value = true
    let ok = true
    /* eslint-disable no-restricted-syntax, no-await-in-loop */
    for (const file of args.files) {
      file.parents = [args.newParentId]
      updateFile(file)
    }
    for (const file of args.files) {
      const response = await moveDriveFile({
        fileId: file.id,
        oldParentId: args.oldParentId,
        newParentId: args.newParentId,
      })
      if (response.status === 200) {
        console.log('moved', file.name)
      } else ok = false
    }
    /* eslint-enable no-restricted-syntax, no-await-in-loop */
    if (ok) {
      console.log('All selected files moved to', args.newParentId)
    }
    pending.value = false
  }
  function trashDriveFiles(files) {
    pending.value = true
    for (const file of files) {
      file.trashed = true
      updateFile(file)
    }
    for (const file of files) {
      trashDriveFile({
        fileId: file.id,
        trashstate: true,
      })
    }
    pending.value = false
  }
  function restoreDriveFiles(files) {
    pending.value = true
    for (const file of files) {
      file.trashed = false
      updateFile(file)
    }
    for (const file of files) {
      trashDriveFile({
        fileId: file.id,
        trashstate: false,
      })
    }
    pending.value = false
  }
  return {
    driveFiles,
    folderCrumbs,
    pending,
    moveDriveFiles,
    trashDriveFiles,
    restoreDriveFiles,
    gotoDriveFolder,
    uploadFile,
    renameFile,
    initDriveFolder,
    createNewFolder,
    createCompanyDrive,
  }
})

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useGoogleDriveStore, import.meta.webpackHot))
}

export default useGoogleDriveStore
