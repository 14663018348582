import { computed } from 'vue'
import { defineStore } from 'pinia'
import { useCollection } from 'vuefire'
import { collection, where, query, orderBy } from 'firebase/firestore'
import { database } from '@/firebase'
import { useUserStore } from '@/users/stores'

export const useFundraisesStore = defineStore('fundraises', () => {
  const userStore = useUserStore()
  const user = computed(() => userStore.user)
  const queryraises = computed(() => query(
    collection(database, 'fundraises'),
    where('companyid', '==', user.value?.companyid || null),
    orderBy('opendate', 'desc'),
  ))
  const { data: fundraises, pending: fundraisesLoading } = useCollection(queryraises, { ssrKey: 'query-raises' })
  const lastFundraise = computed(() => fundraises.value[0])

  return { fundraises, lastFundraise, fundraisesLoading }
})

export default useFundraisesStore
