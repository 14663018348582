import { createRouter, createWebHistory } from 'vue-router'

const NotFound = () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')

const routes = [
  {
    title: 'Not found page',
    path: '/notfound',
    name: 'notfound',
    component: NotFound,
  },
  {
    title: 'Landing page',
    path: '/',
    name: 'landing',
    component: () => import(/* webpackChunkName: "landing" */ '../users/Landing.vue'),
  },
  {
    title: 'Start page',
    path: '/start',
    name: 'start',
    component: () => import(/* webpackChunkName: "start" */ '../users/Start.vue'),
  },
  {
    title: 'Privacy page',
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () => import(/* webpackChunkName: "start" */ '../views/PrivacyPolicy.vue'),
  },
  {
    title: 'Terms page',
    path: '/termsofservice',
    name: 'termsofservice',
    component: () => import(/* webpackChunkName: "start" */ '../views/TermsOfService.vue'),
  },
  {
    path: '/admin/',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "entrepreneur-main" */ '../views/AdminMain.vue'),
    children: [
      {
        path: 'home',
        name: 'adminhome',
        component: () => import(/* webpackChunkName: "admin-utilities" */ '../admin/Utilities.vue'),
      },
      {
        path: 'contacts',
        name: 'admincontacts',
        component: () => import(/* webpackChunkName: "admin-contacts" */ '../admin/Contacts.vue'),
      },
      {
        path: 'contact/:id',
        name: 'admincontact',
        component: () => import(/* webpackChunkName: "admin-invest-org" */ '../admin/ContactInfo.vue'),
        props: true,
      },
      {
        path: 'companies',
        name: 'admincompanies',
        component: () => import(/* webpackChunkName: "companies" */ '../companies/Companies.vue'),
      },
      {
        path: 'company/:id',
        name: 'admincompany',
        component: () => import(/* webpackChunkName: "company" */ '../companies/Company.vue'),
        props: true,
      },
      {
        path: 'investors',
        name: 'admininvestors',
        component: () => import(/* webpackChunkName: "companies" */ '../investors/Investors.vue'),
        props: { admin: true },
      },
      {
        path: 'investors/:id',
        name: 'admininvestorg',
        component: () => import(/* webpackChunkName: "admin-invest-org" */ '../investors/InvestOrgInfo.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/ent/',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "entrepreneur-main" */ '../views/EntrepreneurMain.vue'),
    children: [
      {
        path: 'home',
        name: 'enthome',
        component: () => import(/* webpackChunkName: "entrepreneur-home" */ '../views/EntrepreneurHome.vue'),
      },
      {
        path: 'home/:edit',
        name: 'entedit',
        component: () => import(/* webpackChunkName: "entrepreneur-home" */ '../views/EntrepreneurHome.vue'),
      },
      {
        path: 'businessplan',
        name: 'businessplan',
        component: () => import(/* webpackChunkName: "master-plan" */ '../businessplan/MasterPlan.vue'),
      },
      {
        path: 'fundraises',
        name: 'fundraises',
        component: () => import(/* webpackChunkName: "funding-rounds" */ '../fundraises/FundingRounds.vue'),
      },
      {
        path: 'fundraises/:id',
        name: 'fundraise',
        component: () => import(/* webpackChunkName: "application" */ '../fundraises/FundRaise.vue'),
        props: true,
      },
      {
        path: 'documents',
        name: 'documents',
        component: () => import(/* webpackChunkName: "company-docs" */ '../documents/CompanyDocs.vue'),
      },
      {
        path: 'templates',
        name: 'templates',
        component: () => import(/* webpackChunkName: "templates" */ '../templates/Templates.vue'),
      },
      {
        path: 'investors',
        name: 'investors',
        component: () => import(/* webpackChunkName: "investors" */ '../investors/Investors.vue'),
        props: { admin: false },
      },
      {
        path: 'investors/:id',
        name: 'investorg',
        component: () => import(/* webpackChunkName: "invest-org" */ '../investors/InvestorOrg.vue'),
        props: true,
      },
      {
        path: 'applications',
        name: 'applications',
        component: () => import(/* webpackChunkName: "applications" */ '../applications/Applications.vue'),
      },
      {
        path: 'applications/:id',
        name: 'application',
        component: () => import(/* webpackChunkName: "application" */ '../applications/Application.vue'),
        props: true,
      },
      {
        path: 'captable',
        name: 'captable',
        component: () => import(/* webpackChunkName: "cap-table" */ '../investments/CapTable.vue'),
      },
      {
        path: 'tasks',
        name: 'tasks',
        component: () => import(/* webpackChunkName: "tasks" */ '../tasks/Tasks.vue'),
      },
      {
        path: 'notes',
        name: 'notes',
        component: () => import(/* webpackChunkName: "calls" */ '../notes/Notes.vue'),
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: () => import(/* webpackChunkName: "contacts" */ '../contacts/Contacts.vue'),
      },
      {
        path: 'calls',
        name: 'calls',
        component: () => import(/* webpackChunkName: "calls" */ '../calls/Calls.vue'),
      },
      {
        path: 'emails',
        name: 'emails',
        component: () => import(/* webpackChunkName: "emails" */ '../emails/Emails.vue'),
      },
      {
        path: 'emails/:id',
        name: 'emailthread',
        component: () => import(/* webpackChunkName: "thread-page" */ '../emails/EmailThread.vue'),
      },
      {
        path: 'events',
        name: 'events',
        component: () => import(/* webpackChunkName: "events" */ '../events/Events.vue'),
      },
      {
        path: 'test',
        name: 'test',
        component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue'),
      },
    ],
  },
  {
    path: '/inv/',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "investor-main" */ '../views/InvestorMain.vue'),
    children: [
      {
        path: 'home',
        name: 'invhome',
        component: () => import(/* webpackChunkName: "investor-home" */ '../views/InvestorHome.vue'),
      },
      {
        path: 'deals',
        name: 'deals',
        component: () => import(/* webpackChunkName: "deals" */ '../deals/Deals.vue'),
      },
      {
        path: 'deal/:id',
        name: 'deal',
        component: () => import(/* webpackChunkName: "deal" */ '../deals/Deal.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
