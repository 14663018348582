import axios from 'axios'
import { functionURL } from '@/utilities'

export async function getDriveFileMetadata(params) {
  return axios.get(`${functionURL}googledrive-getDriveFileMetadata`, { params })
}

export async function listDriveFiles(params) {
  return axios.get(`${functionURL}googledrive-listDriveFiles`, { params })
}

export async function trashDriveFile(params) {
  return axios.get(`${functionURL}googledrive-trashDriveFile`, { params })
}

export async function moveDriveFile(params) {
  return axios.get(`${functionURL}googledrive-moveDriveFile`, { params })
}

export async function renameDriveFile(params) {
  return axios.get(`${functionURL}googledrive-renameDriveFile`, { params })
}

export async function shareDriveFile(params) {
  return axios.get(`${functionURL}googledrive-shareDriveFile`, { params })
}

export async function uploadDriveFile(params) {
  return axios.get(`${functionURL}googledrive-uploadDriveFile`, { params })
}

export async function createDriveFile(params) {
  return axios.get(`${functionURL}googledrive-createDriveFile`, { params })
}

export async function createDriveFolder(params) {
  return axios.get(`${functionURL}googledrive-createDriveFolder`, { params })
}

export async function createShortcut(params) {
  return axios.get(`${functionURL}googledrive-createShortcut`, { params })
}

export async function createSharedDrive(params) {
  return axios.get(`${functionURL}googledrive-createSharedDrive`, { params })
}

export async function setupCompanyDrive(params) {
  return axios.get(`${functionURL}googledrive-setupCompanyDrive`, { params })
}

export async function setupUserCompanyDrive(params) {
  return axios.get(`${functionURL}googledrive-setupUserCompanyDrive`, { params })
}